enum BodyType {
  BOAT = 'Boat',
  BUS = 'Bus',
  COMMERCIAL_VEHICLE = 'Commercial Vehicle',
  CONVERTIBLE = 'Convertible',
  DUMP_TRUCK = 'Dump Truck',
  FLATBED_TRUCK = 'Flatbed Truck',
  HATCHBACK = 'Hatchback',
  HEARSE = 'Hearse',
  LIMOUSINE = 'Limousine',
  MOPED = 'Moped',
  MOTORCYCLE = 'Motorcycle',
  PICKUP_TRUCK = 'Pickup Truck',
  REFRIGERATION_TRUCK = 'Refrigeration Truck',
  RV = 'RV',
  SEDAN = 'Sedan',
  SEMI_TRAILER = 'Semi Trailer',
  SNOWMOBILE = 'Snowmobile',
  STAKE_TRUCK = 'Stake Truck',
  STATION_WAGON = 'Station Wagon',
  SUV = 'SUV',
  TANK_TRUCK = 'Tank Truck',
  TAXI = 'Taxi',
  TOW_TRUCK = 'Tow Truck',
  TRACTION_ENGINE = 'Traction Engine',
  TRACTOR = 'Tractor',
  TRACTOR_CRANE = 'Tractor Crane',
  TRAILER = 'Trailer',
  TRUCK = 'Truck',
  TRUCK_CRANE = 'Truck Crane',
  UNKNOWN_BODY_TYPE = 'Unknown Body Type',
  UTILITY_VEHICLE = 'Utility Vehicle',
  VAN = 'Van',
}

export default BodyType
