enum Make {
  ACURA = 'Acura',
  ALFA_ROMEO = 'Alfa Romeo',
  AUDI = 'Audi',
  BENTLEY = 'Bentley',
  BMW = 'BMW',
  BUICK = 'Buick',
  CADILLAC = 'Cadillac',
  CHEVROLET = 'Chevrolet',
  CHRYSLER = 'Chrysler',
  CITROEN = 'Citroen',
  DATSUN = 'Datsun',
  DODGE = 'Dodge',
  DUCATI = 'Ducati',
  FERRARI = 'Ferrari',
  FIAT = 'Fiat',
  FORD = 'Ford',
  FREIGHT = 'Freight',
  GMC = 'GMC',
  HARLEY_DAVIDSON = 'Harley-Davidson',
  HINO = 'Hino',
  HONDA = 'Honda',
  HUMMER = 'Hummer',
  HYUNDAI = 'Hyundai',
  INFINITI = 'Infiniti',
  INTERNATIONAL = 'International',
  ISUZU = 'Isuzu',
  JAGUAR = 'Jaguar',
  JEEP = 'Jeep',
  KAWASAKI = 'Kawasaki',
  KIA = 'Kia',
  LAMBORGHINI = 'Lamborghini',
  LAND_ROVER = 'Land Rover',
  LEXUS = 'Lexus',
  LINCOLN = 'Lincoln',
  MACK = 'Mack',
  MASERATI = 'Maserati',
  MAZDA = 'Mazda',
  MERCEDES_BENZ = 'Mercedes-Benz',
  MERCURY = 'Mercury',
  MCI_COACH = 'MCI Coach',
  MINI_COOPER = 'Mini Cooper',
  MITSUBISHI = 'Mitsubishi',
  NISSAN = 'Nissan',
  OLDSMOBILE = 'Oldsmobile',
  PETERBILT = 'Peterbilt',
  PEUGEOT = 'Peugeot',
  PLYMOUTH = 'Plymouth',
  PONTIAC = 'Pontiac',
  PORSCHE = 'Porsche',
  PREVOST = 'Prevost',
  REVEL = 'Revel',
  SAAB = 'Saab',
  SATURN = 'Saturn',
  SCION = 'Scion',
  SMART = 'Smart',
  STERLING = 'Sterling',
  SUBARU = 'Subaru',
  SUZUKI = 'Suzuki',
  TESLA = 'Tesla',
  THOMAS_BUILT_BUSES = 'Thomas Built Buses',
  TOYOTA = 'Toyota',
  TRIUMPH = 'Triumph',
  UD_TRUCK = 'UD Truck',
  UNKNOWN_MAKE = 'Unknown Make',
  UTILITY_VEHICLE = 'Utility Vehicle',
  VAN = 'Van',
  VESPA = 'Vespa',
  VOLKSWAGEN = 'Volkswagen',
  VOLVO = 'Volvo',
  YAMAH = 'Yamaha',
}

export default Make
